body {
    margin: 0;
    padding: 5px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-flow: column;
    align-items: center;
}

button,
a {
    background-color: transparent;
    border: none;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
