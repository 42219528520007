@import './ScrollContainer.common';

.scroll-container {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);

    &:not(:empty):not([data-heading=''])::before {
        background-color: #21aba8;
        color: #fff;
        box-shadow: 0 0 5px rgb(0 0 0 / 25%);
    }
}

.scroll-container__content {
    border: 1px solid rgba(0, 0, 0, 0.1);
}
