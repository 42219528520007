.box {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 30px 40px;
    border-radius: 5px;
    width: min(550px, 95vw);
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);

    @media (max-height: 680px) {
        & {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    @media (max-height: 640px) {
        & {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    @media (max-height: 590px) {
        & {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    @media (max-width: 450px) {
        & {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
