.button.icon-button {
    padding-top: 7px;
    padding-bottom: 7px;

    .icon:nth-child(2):not(:last-child) {
        margin-left: -6px;
    }

    .icon:last-child:not(:nth-child(2)) {
        margin-right: -7px;
    }

    &:hover {
        animation: none;
    }
}

// .button.icon-button.variant--raised,
// .button.icon-button.variant--stroked {
//     .icon:nth-child(2):not(:last-child) {
//         margin-right: 5px;
//     }
// }

.button.icon-button.variant--raised {
    border-width: 2px;
    border-style: solid;
}
