@import '@constants.common';

.dialog-container {
    opacity: 0;

    &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}

.dialog {
    transform-origin: 50% 50%;
    position: fixed;
    z-index: 24;
    top: auto;
    left: auto;
    animation-duration: 250ms;
    animation-timing-function: $easing-function;
    animation-fill-mode: forwards;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 5px;
    overflow: hidden;
}

.dialog--entering {
    animation-name: dialog--entering;
}

.dialog--leaving {
    animation-name: dialog--leaving;
}

@keyframes dialog--entering {
    from {
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px) scale(0.95, 0.95);
    }

    to {
        visibility: visible;
        opacity: 1;
        transform: translateY(0) scale(1, 1);
    }
}

@keyframes dialog--leaving {
    from {
        visibility: visible;
        opacity: 1;
        transform: translateY(0) scale(1, 1);
    }

    to {
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px) scale(0.99, 0.99);
    }
}