.dialog-action-group {
    width: 100%;
    padding: 20px;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;

    &>* {
        margin-right: 5px;
    }

    &:empty {
        display: none;
    }
}