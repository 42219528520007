.fade-in {
    animation: fadein 500ms ease forwards;
}

.fade-out {
    animation: fadeout 500ms ease forwards;
    pointer-events: none;
}

@keyframes fadein {
    from {
        visibility: hidden;
        opacity: 0;
    }

    to {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        visibility: visible;
        opacity: 1;
    }

    to {
        visibility: hidden;
        opacity: 0;
    }
}