.radio-button,
.radio-button__circle {
    display: flex;
    position: relative;
    align-items: center;
}

.radio-button__circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 1000px;
}

.radio-button__icon {
    position: relative;
    font-size: 1.5em;
    pointer-events: none;
}

.radio-button__checked-icon {
    position: absolute;
    transition: transition(opacity);
    opacity: 0;
}

.radio-button__input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
    display: block;

    &:checked ~ .radio-button__checked-icon {
        opacity: 1;
    }
}

.radio-button__label-container {
    display: flex;
    align-items: center;
}

.radio-button__label {
    cursor: pointer;
    margin-top: 2px;
    .ripple {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px !important;
        height: 40px !important;
        pointer-events: none;
        border-radius: 100%;
    }

    .ripple__effect {
        width: 100% !important;
        height: 100% !important;
    }
}
