@import '@constants.common';

.button {
    position: relative;
    padding: 11px 15px;
    border-radius: 4px;
    transition: transition(background-color);
    cursor: pointer;
    transform-origin: 50% 50%;
    backface-visibility: hidden;

    &:disabled {
        opacity: 0.3;
        pointer-events: none;
        box-shadow: none !important;
    }
}

.button.variant--raised {
    border-width: 2px;
    border-style: solid;
}

.button.variant--stroked {
    border-width: 2px;
    border-style: solid;
}

.icon + .button__label {
    margin-left: 5px;
}

.button__label + .icon {
    margin-right: 5px;
}
