@import '@constants.light';

header {
    text-align: center;
    margin-bottom: 10px;
    h2 {
        font-size: medium;
        margin-bottom: 0;
    }
    a {
        font-size: small;
    }
}

.file-list {
    margin: 0;
    padding: 0;
}

.file-list__item {
    margin: 0;
    padding: 5px 0;
    list-style-type: none;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    &.failed {
        .file-list__upload-status {
            display: inline-block;
        }
    }
}

.file-list__remove-item {
    border-radius: 100%;
    width: 45px;
    height: 45px;
}

.file-list__upload-details {
    display: flex;
    flex-flow: column;
    margin-left: 5px;
}

.file-list__file-name {
    font-weight: bold;
}

.file-list__file-size {
    color: gray;
    margin-top: 5px;
    font-size: small;
}

.file-list__upload-status {
    display: none;
    margin-top: 3px;
    font-size: small;
    color: red;
}

.generator-options .scroll-container {
    padding-left: 20px;
    padding-right: 20px;
}

.clear-all-files {
    margin-bottom: 5px;
}

.generate-pdf {
    background-color: lighten($color: $primary-color, $amount: 1);
    color: #fff !important;

    .ripple__effect {
        background-color: rgba(255, 255, 255, 0.15) !important;
    }
}

.box.how-to-download-container {
    margin-top: 30px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    ul {
        margin: 0;
    }
}

.how-to-download-steps:not(:first-of-type) {
    margin-top: 10px;
}

.how-to-download-step {
    line-height: 175%;
}
