.ripple {
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ripple.fixed {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ripple__effect {
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    animation: ripple cubic-bezier(0.04, 0.54, 0.25, 1) 0;
    opacity: 0.7;
    transform: scale(0);
    border-radius: 100%;
    transform-origin: 50% 50%;
    top: 0;
    left: 0;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0, 0);
    }

    to {
        opacity: 0;
        transform: scale(3, 3);
    }
}
