@import '@constants.common';

.notification-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    pointer-events: initial;
}

.notification {
    position: relative;
    bottom: 20px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 20px;
    box-sizing: border-box;
    min-height: 60px;
    border-radius: 5px;
    animation: 0.25s $easing-function forwards;
    max-width: min(600px, 95vw);
    font-size: 1rem;
    line-height: 20px;
    backface-visibility: hidden;
}

.notification-container.visible .notification {
    animation-name: notification--enter;
}

.notification-container.hidden .notification {
    animation-name: notification--leave;
}

@keyframes notification--enter {
    from {
        transform: scale(0.95, 0.95);
        opacity: 0;
    }

    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes notification--leave {
    from {
        transform: scale(1, 1);
        opacity: 1;
    }

    to {
        transform: scale(0.95, 0.95);
        opacity: 0;
    }
}

.notification__close {
    right: 0;
    margin-left: 15px;
    pointer-events: all;
}
