@import './FormField.common';

.form-field {
    &.invalid {

        .form-field__label,
        .form-field__label-hint {
            color: red;
        }
    }

    &.disabled {
        .form-field__label {
            color: #cdcdcd;
        }

        .form-field__label-hint {
            color: #ddd;
        }

    }
}

.form-field__label {
    color: #000;
}

.form-field__label-hint {
    color: rgba(0, 0, 0, 0.5);
}