@import '@constants.common';

.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    animation-duration: 250ms;
    animation-timing-function: $easing-function;
    animation-fill-mode: forwards;
    z-index: 15;
    visibility: hidden;
    opacity: 0;
    background-color: transparent;
}