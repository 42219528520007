@import 'Notification.common';

.notification {
    box-shadow: 0 0 5px #3c3c3c;
    background-color: #1b1616;
    color: #ffc65f;

    .notification__close {
        color: #9c9c9c !important;

        .ripple__effect {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}