@import '@constants.common';

.form-field {
    margin: 20px 0;
    display: flex;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.form-field__label {
    display: flex;
    flex-flow: column;
    transition: transition(color);
    position: relative;
    flex-shrink: 0;
    pointer-events: none;
    font-weight: 600;
}

.form-field__label-hint {
    display: inline-block;
    font-size: 0.8em;
    transition: transition(color);
}