@import '@constants.light';
@import './Button.common';

.button.variant--raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.11);
    color: #fff;

    &.color--primary {
        background-color: $primary-color;
        border-color: #4b69b8;
    }

    &.color--warning {
        background-color: $warning-color;
        border-color: #ed0d0d;
    }

    .ripple__effect {
        background-color: rgba(255, 255, 255, 0.25);
    }

    &:hover {
        &.color--primary {
            background-color: darken($primary-color, 10);
        }

        &.color--accent {
            background-color: darken($primary-color, 10);
        }

        &.color--warning {
            background-color: darken($warning-color, 10);
        }
    }
}

.button.variant--stroked {
    &.color--primary {
        color: $primary-color;
        border-color: $primary-color;
    }
    &.color--accent {
        border-color: #abafc7;
    }
    &.color--warning {
        color: $warning-color;
        border-color: $warning-color;
    }

    &:hover {
        &.color--primary {
            background-color: #efefef;
        }
        &.color--accent {
            background-color: darken(#fff, 7);
        }

        &.color--warning {
            background-color: darken($warning-color, 7);
        }
    }
}

.button.variant--basic {
    &.color--primary {
        color: $primary-color;
    }

    &.color--warning {
        color: $warning-color;
    }
}
