.scroll-container {
    margin: 20px 0;
    padding: 0;
    border-radius: 3px;
    overflow: hidden;

    &:not(:empty):not([data-heading=''])::before {
        content: attr(data-heading);
        display: block;
        padding: 15px 0;
        text-align: center;
        position: relative;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }
}

.scroll-container__content {
    position: relative;
    overflow: auto;
    max-height: 40vh;
    padding: 0 10px;
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-sizing: border-box;
}
