@import '@constants.common';

.dialog.confirmation-dialog {
    white-space: pre-wrap;
    display: flex;
    flex-flow: row;
    align-items: center;
    bottom: 20px;
    max-width: min(600px, 95vw);
    padding: 10px;
    padding-left: 20px;
    padding-right: 10px;
    box-sizing: border-box;
    min-height: 60px;
    border-radius: 5px;
    border: none;

    &.dialog--enter {
        animation-name: confirmation-dialog--enter;
    }

    &.dialog--leave {
        animation-name: confirmation-dialog--leave;
    }

    .dialog-content {
        padding: 0;
    }

    .dialog-action-group {
        width: initial;
        margin: 0;
        margin-left: 20px;
        padding: 0;
        top: 1px;
        position: relative;

        > * {
            font-size: 1rem;
            margin: 0;
        }
    }
}

@keyframes confirmation-dialog--enter {
    from {
        transform: translateX(-50%) scale(0.95, 0.95);
        opacity: 0;
    }

    to {
        transform: translateX(-50%) scale(1, 1);
        opacity: 1;
    }
}

@keyframes confirmation-dialog--leave {
    from {
        transform: translateX(-50%) scale(1, 1);
        opacity: 1;
    }

    to {
        transform: translateX(-50%) scale(0.95, 0.95);
        opacity: 0;
    }
}
