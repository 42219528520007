@import './ConfirmationDialog.common';

.dialog.confirmation-dialog {
    box-shadow: 0 3px 5px -1px #0003, 0 6px 10px 0 #00000024, 0 1px 18px 0 #0000001f;
    background-color: #1b1616;
    color: #ffc65f;

    .dialog-action-group {
        button:first-child {
            color: #9c9c9c;

            .ripple__effect {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }

        button:last-child {
            color: #ffa500;
            background-color: transparent;
            border: none;

            .ripple__effect {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
    }
}